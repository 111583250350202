<template>
  <div class="container">
    <div v-if="pageDataLoaded === true">
      <h2 class="page-title mb4">
        <oc-content :item="$oc.content.privacyPolicyPageTitle" />
      </h2>
      <oc-content :item="$oc.content.privacyPolicyPageBody" />
    </div>
  </div>
</template>

<script>
import { getOrchardCoreContentModule } from '../lib/api'
import pageMixin from '../lib/mixins/page'

export default {
  name: 'Privacy',
  mixins: [pageMixin],
  async mounted () {
    try {
      const response = await getOrchardCoreContentModule('privacy-policy-page')
      this.$oc.setContent(response.data)
      this.pageDataLoaded = true
    } catch {
      this.pageDataLoaded = false
    }
  },
  metaInfo: {
    title: 'Benefit Buddy - Privacy Policy'
  }
}
</script>

<style>

</style>
